.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .4), 0 1px 5px 0 rgba(0, 0, 0, .2), 0 3px 1px -2px rgba(0, 0, 0, .2); }

.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .4), 0 1px 10px 0 rgba(0, 0, 0, .2), 0 2px 4px -1px rgba(0, 0, 0, .3); }

.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .4), 0 1px 18px 0 rgba(0, 0, 0, .2), 0 3px 5px -1px rgba(0, 0, 0, .3); }

.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .4), 0 3px 14px 2px rgba(0, 0, 0, .2), 0 5px 5px -3px rgba(0, 0, 0, .3); }

.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .4), 0 6px 30px 5px rgba(0, 0, 0, .2), 0 8px 10px -5px rgba(0, 0, 0, .3); }
