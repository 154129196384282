.app {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  & > .header {
    height: auto;
    width: 100%;

    & > .error-navbar {
      width: inherit;
      padding: 10px 10px 10px 30px;
      background-color: #fff;
      display: flex;
      flex-direction: row;

      & > .navbar-logo {
        width: 132px;
        height: 30px; }

      & > a {
        width: 80px;
        height: auto;
        padding: 5px 0 5px 0; } } }

  & > .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    padding: 70px 0px 0px 0px;

    & > .title {
      font-family: Biryani !important;
      font-weight: bolder;
      font-size: 40px;
      line-height: 1.2; }

    & > span, a {
      font-family: Ubuntu !important;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      line-height: 32px; }

    & > .text {
      margin: 30px 0 0 0; }

    & > a {
      color: #0191c1; }

    & > img {
      margin: 30px 0 0 0; } } }
