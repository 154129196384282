// $c-main: #212529
$c-main: #0d1738;

$c-border-error: #FF9696;
$c-content-error: #FF0000;

$c-border-form: #CCC;

$c-primary: #01B3E3;
$c-secondary: #FFDE83;

$c-red: #ff685c;
$c-red-error: #f24f4f;
$c-black: #0A0A0A;
$c-black-absolute: #000000;
$c-white: #FFFFFF;
$c-light-gray: #ededed;
$c-slate-gray: #aaaaaa;
$c-dark-gray: #1a1a1a;
$c-turquoise: #5fcde9;
$-duoc: #666666;

$c-background: #f6f6f6;
$c-placeholder: #808080;
$c-disabled: #ebebe4;
$c-cinema-background: #111213;
$c-content: #2E3D49;

$c-feedback-audio: #e4880f;
$c-feedback-text: #4380ed;
$c-feedback-common-mistake: #28ce9e;
$c-feedback-drawing: #b237d8;

$student-color: #1ad9a5;
$teacher-color: #0191c1;
$--uc-primario-celeste: #0176de;
$color-svg-primary: #ededed;
$--approved-green-color: #56c594;
$--archieve-green-color: #cff4d1;
$--warm-grey-16: #888888;
$--l-neas-separadoras: #ededed;

@function c-gray($value) {
  @if $value == 50 {
    @return #FAFAFA; }
  @else if $value == 100 {
    @return #F5F5F5; }
  @else if $value == 200 {
    @return #EEEEEE; }
  @else if $value == 300 {
    @return #E0E0E0; }
  @else if $value == 400 {
    @return #BDBDBD; }
  @else if $value == 500 {
    @return #9E9E9E; }
  @else if $value == 600 {
    @return #757575; }
  @else if $value == 700 {
    @return #616161; }
  @else if $value == 800 {
    @return #424242; }
  @else if $value == 900 {
    @return #212121; } }

// $c-primary: #FF9800
