@import url(https://fonts.googleapis.com/css?family=Ubuntu:100,300,400,500,700,800,400i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,800,400i);
@import url(https://fonts.googleapis.com/css?family=Biryani:100,300,400,500,700,800,400i);
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400&display=swap');

$f-family-primary: 'Ubuntu';
$f-family-secondary: 'Biryani';

$f-size-sm: .8em;
$f-size-md: 1em;
$f-size-lg: 1.1em;
$f-size-xl: 1.5em;
$f-size-xxl: 2em;
