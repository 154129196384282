@import "shadow";
@import './lib/index.sass';

* {
  box-sizing: border-box;
  font-family: 'Titillium Web' !important; }

tspan {
  font-family: 'Titillium Web', sans-serif !important; }

body, a {
  color: $c-black; }

a {
  text-decoration: none;
  font-family: 'Titillium Web' !important;
  font-weight: 600; }

body {
  font-family: $f-family-primary;
  margin: 0;
  padding: 0;
  background-color: $c-background; }

button {
  font-family: $f-family-primary;
  font-size: $f-size-md;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  @include d-flex(center, center); }

input {
  width: 100%;
  min-width: 0;
  border: none;
  outline: none;
  background: none; }

p {
  // margin: 0
  letter-spacing: 0.18px !important;
  font-family: 'Titillium Web' !important; }

h2 {
  font-weight: 400;
  font-family: 'Titillium Web' !important;
  letter-spacing: 0.23px !important; }

h3, h4 {
  letter-spacing: 0.18px !important; }

h5, h6 {
  letter-spacing: 0.1px !important; }

h1 {
  letter-spacing: 0px !important; }

h1, h3, h4, h5, h6 {
  font-weight: 600 !important;
  font-family: 'Titillium Web' !important; }

.button {
   box-shadow: 0 4px 4px 0 rgba(68, 68, 68, 0.16); }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.card {
  border-radius: 3.6px;
  box-shadow: 0 0 8px 0 hsla(0,0%,53.3%,.18);
  background-color: #ffffff; }

.course-card {
  box-shadow: 0 0 8px 0 hsla(0,0%,53.3%,.18); }

.course-card-large {
  box-shadow: 0 0 8px 0 hsla(0,0%,53.3%,.18); }

.image-status {
  box-shadow: 0 0 8px 0 hsla(0,0%,53.3%,.18) !important; }

