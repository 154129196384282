
@mfeedbackType.length > 0edia (min-width: 1940px);
body {
  background-color: white !important;

  #app {
    margin-left: 0%; } }

#app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.DUOC-Background-color {
  background-color: #1a1a1a !important;
  color: White !important;

  p h1 {
    background: #0db7b4;
    color: white !important; }
  div {
    p h1 {
      background: #0db7b4;
      color: white !important; } } }

.video-container-iframe iframe {
  width: 100% !important;
  height: 100% !important;
  min-height: 413px; }

.video-container-iframe-small iframe {
  width: 100% !important;
  height: 100% !important; }

