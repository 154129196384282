.loading-container {
  text-align: center;

  .helper {
    display: inline-block;
    vertical-align: middle;
    height: 100%; }

  img.loading {
    vertical-align: middle;
    width: 200px;
    height: 200px; } }
